@import './variables.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.w-99 {
  width: 99%;
}

.form-grey {
  background-color: #f7f7f7 !important;
}

.subtext {
  color: $ui-gray-4;
}

.gray-hover:hover {
  background-color: $ui-gray-8 !important;
}
